/* istanbul ignore next */
/**
 * This is a syntactic sugar for `columns` prop.
 * So HOC will not work on this.
 */
// eslint-disable-next-line no-unused-vars
function ColumnGroup(_) {
    return null;
}
export default ColumnGroup;

import * as React from 'react';
export default function useSyncState(filteredKeys) {
    const filteredKeysRef = React.useRef(filteredKeys);
    const [, forceUpdate] = React.useState(null);
    return [
        () => filteredKeysRef.current,
        (newValue) => {
            filteredKeysRef.current = newValue;
            // re-render
            forceUpdate({});
        },
    ];
}

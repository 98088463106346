import * as React from 'react';
import ResizeObserver from 'rc-resize-observer';
import omit from 'omit.js';
import classNames from 'classnames';
import calculateNodeHeight from './calculateNodeHeight';
import raf from '../_util/raf';
const RESIZE_STATUS_NONE = 0;
const RESIZE_STATUS_RESIZING = 1;
const RESIZE_STATUS_RESIZED = 2;
class ResizableTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.saveTextArea = (textArea) => {
            this.textArea = textArea;
        };
        this.handleResize = (size) => {
            const { resizeStatus } = this.state;
            const { autoSize, onResize } = this.props;
            if (resizeStatus !== RESIZE_STATUS_NONE) {
                return;
            }
            if (typeof onResize === 'function') {
                onResize(size);
            }
            if (autoSize) {
                this.resizeOnNextFrame();
            }
        };
        this.resizeOnNextFrame = () => {
            raf.cancel(this.nextFrameActionId);
            this.nextFrameActionId = raf(this.resizeTextarea);
        };
        this.resizeTextarea = () => {
            const { autoSize } = this.props;
            if (!autoSize || !this.textArea) {
                return;
            }
            const { minRows, maxRows } = autoSize;
            const textareaStyles = calculateNodeHeight(this.textArea, false, minRows, maxRows);
            this.setState({ textareaStyles, resizeStatus: RESIZE_STATUS_RESIZING }, () => {
                raf.cancel(this.resizeFrameId);
                this.resizeFrameId = raf(() => {
                    this.setState({ resizeStatus: RESIZE_STATUS_RESIZED }, () => {
                        this.resizeFrameId = raf(() => {
                            this.setState({ resizeStatus: RESIZE_STATUS_NONE });
                        });
                    });
                });
            });
        };
        this.renderTextArea = () => {
            const { prefixCls, autoSize, onResize, className, disabled } = this.props;
            const { textareaStyles, resizeStatus } = this.state;
            const otherProps = omit(this.props, [
                'prefixCls',
                'onPressEnter',
                'autoSize',
                'defaultValue',
                'allowClear',
                'onResize',
            ]);
            const cls = classNames(prefixCls, className, {
                [`${prefixCls}-disabled`]: disabled,
            });
            // Fix https://github.com/ant-design/ant-design/issues/6776
            // Make sure it could be reset when using form.getFieldDecorator
            if ('value' in otherProps) {
                otherProps.value = otherProps.value || '';
            }
            const style = Object.assign(Object.assign(Object.assign({}, this.props.style), textareaStyles), (resizeStatus === RESIZE_STATUS_RESIZING ? { overflow: 'hidden' } : null));
            return (<ResizeObserver onResize={this.handleResize} disabled={!(autoSize || onResize)}>
        <textarea {...otherProps} className={cls} style={style} ref={this.saveTextArea}/>
      </ResizeObserver>);
        };
        this.state = {
            textareaStyles: {},
            resizeStatus: RESIZE_STATUS_NONE,
        };
    }
    componentDidMount() {
        this.resizeTextarea();
    }
    componentDidUpdate(prevProps) {
        // Re-render with the new content then recalculate the height as required.
        if (prevProps.value !== this.props.value) {
            this.resizeTextarea();
        }
    }
    componentWillUnmount() {
        raf.cancel(this.nextFrameActionId);
        raf.cancel(this.resizeFrameId);
    }
    render() {
        return this.renderTextArea();
    }
}
export default ResizableTextArea;

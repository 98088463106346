import * as React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line react/prefer-stateless-function
class Element extends React.Component {
    render() {
        const { prefixCls, className, style, size, shape } = this.props;
        const sizeCls = classNames({
            [`${prefixCls}-lg`]: size === 'large',
            [`${prefixCls}-sm`]: size === 'small',
        });
        const shapeCls = classNames({
            [`${prefixCls}-circle`]: shape === 'circle',
            [`${prefixCls}-square`]: shape === 'square',
            [`${prefixCls}-round`]: shape === 'round',
        });
        const sizeStyle = typeof size === 'number'
            ? {
                width: size,
                height: size,
                lineHeight: `${size}px`,
            }
            : {};
        return (<span className={classNames(prefixCls, className, sizeCls, shapeCls)} style={Object.assign(Object.assign({}, sizeStyle), style)}/>);
    }
}
export default Element;

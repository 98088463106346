import * as React from 'react';
import classNames from 'classnames';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled, } from '@ant-design/icons';
import useMemo from 'rc-util/lib/hooks/useMemo';
import CSSMotion from 'rc-animate/lib/CSSMotion';
import Col from '../grid/col';
import { FormContext } from './context';
import { useCacheErrors } from './util';
const iconMap = {
    success: CheckCircleFilled,
    warning: ExclamationCircleFilled,
    error: CloseCircleFilled,
    validating: LoadingOutlined,
};
const FormItemInput = ({ prefixCls, wrapperCol, children, help, errors, onDomErrorVisibleChange, hasFeedback, validateStatus, extra, }) => {
    const [, forceUpdate] = React.useState({});
    const baseClassName = `${prefixCls}-item`;
    const formContext = React.useContext(FormContext);
    const mergedWrapperCol = wrapperCol || formContext.wrapperCol || {};
    const className = classNames(`${baseClassName}-control`, mergedWrapperCol.className);
    const [visible, cacheErrors] = useCacheErrors(errors, changedVisible => {
        if (changedVisible) {
            onDomErrorVisibleChange(true);
        }
        forceUpdate({});
    }, !!help);
    const memoErrors = useMemo(() => cacheErrors, visible, (_, nextVisible) => nextVisible);
    // Should provides additional icon if `hasFeedback`
    const IconNode = validateStatus && iconMap[validateStatus];
    const icon = hasFeedback && IconNode ? (<span className={`${baseClassName}-children-icon`}>
        <IconNode />
      </span>) : null;
    // Pass to sub FormItem should not with col info
    const subFormContext = Object.assign({}, formContext);
    delete subFormContext.labelCol;
    delete subFormContext.wrapperCol;
    return (<FormContext.Provider value={subFormContext}>
      <Col {...mergedWrapperCol} className={className}>
        <div className={`${baseClassName}-control-input`}>
          <div className={`${baseClassName}-control-input-content`}>{children}</div>
          {icon}
        </div>
        <CSSMotion visible={visible} motionName="show-help" onLeaveEnd={() => {
        onDomErrorVisibleChange(false);
    }} motionAppear removeOnLeave>
          {({ className: motionClassName }) => {
        return (<div className={classNames(`${baseClassName}-explain`, motionClassName)} key="help">
                {memoErrors.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{error}</div>))}
              </div>);
    }}
        </CSSMotion>
        {extra && <div className={`${baseClassName}-extra`}>{extra}</div>}
      </Col>
    </FormContext.Provider>);
};
export default FormItemInput;

import * as React from 'react';
import Alert from '.';
export default class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: undefined,
            info: {
                componentStack: '',
            },
        };
    }
    componentDidCatch(error, info) {
        this.setState({ error, info });
    }
    render() {
        const { message, description, children } = this.props;
        const { error, info } = this.state;
        const componentStack = info && info.componentStack ? info.componentStack : null;
        const errorMessage = typeof message === 'undefined' ? (error || '').toString() : message;
        const errorDescription = typeof description === 'undefined' ? componentStack : description;
        if (error) {
            // You can render any custom fallback UI
            return (<Alert type="error" message={errorMessage} description={<pre>{errorDescription}</pre>}/>);
        }
        return children;
    }
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
export const DEFAULT_PAGE_SIZE = 10;
export function getPaginationParam(pagination, mergedPagination) {
    const param = {
        current: mergedPagination.current,
        pageSize: mergedPagination.pageSize,
    };
    const paginationObj = pagination && typeof pagination === 'object' ? pagination : {};
    Object.keys(paginationObj).forEach(pageProp => {
        const value = mergedPagination[pageProp];
        if (typeof value !== 'function') {
            param[pageProp] = value;
        }
    });
    return param;
}
export default function usePagination(total, pagination, onChange) {
    const _a = pagination && typeof pagination === 'object' ? pagination : {}, { total: paginationTotal = 0 } = _a, paginationObj = __rest(_a, ["total"]);
    const [innerPagination, setInnerPagination] = useState(() => {
        return {
            current: 'defaultCurrent' in paginationObj ? paginationObj.defaultCurrent : 1,
            pageSize: 'defaultPageSize' in paginationObj ? paginationObj.defaultPageSize : DEFAULT_PAGE_SIZE,
        };
    });
    // ============ Basic Pagination Config ============
    const mergedPagination = Object.assign(Object.assign(Object.assign({}, innerPagination), paginationObj), { total: paginationTotal > 0 ? paginationTotal : total });
    if (!paginationTotal) {
        // Reset `current` if data length changed. Only reset when paginationObj do not have total
        const maxPage = Math.ceil(total / mergedPagination.pageSize);
        if (maxPage < mergedPagination.current) {
            mergedPagination.current = 1;
        }
    }
    const refreshPagination = (current = 1) => {
        setInnerPagination(Object.assign(Object.assign({}, mergedPagination), { current }));
    };
    const onInternalChange = (...args) => {
        const [current] = args;
        refreshPagination(current);
        onChange(current, args[1] || mergedPagination.pageSize);
        if (pagination && pagination.onChange) {
            pagination.onChange(...args);
        }
    };
    const onInternalShowSizeChange = (...args) => {
        const [, pageSize] = args;
        setInnerPagination(Object.assign(Object.assign({}, mergedPagination), { current: 1, pageSize }));
        onChange(1, pageSize);
        if (pagination && pagination.onShowSizeChange) {
            pagination.onShowSizeChange(...args);
        }
    };
    if (pagination === false) {
        return [{}, () => { }];
    }
    return [
        Object.assign(Object.assign({}, mergedPagination), { onChange: onInternalChange, onShowSizeChange: onInternalShowSizeChange }),
        refreshPagination,
    ];
}

import * as React from 'react';
import Tooltip from '../tooltip';
export default function SliderTooltip(props) {
    const { visible } = props;
    const tooltipRef = React.useRef(null);
    const rafRef = React.useRef(null);
    function cancelKeepAlign() {
        window.cancelAnimationFrame(rafRef.current);
        rafRef.current = null;
    }
    function keepAlign() {
        if (rafRef.current !== null) {
            return;
        }
        rafRef.current = window.requestAnimationFrame(() => {
            if (tooltipRef.current && tooltipRef.current.tooltip) {
                tooltipRef.current.tooltip.forcePopupAlign();
            }
            rafRef.current = null;
            keepAlign();
        });
    }
    React.useEffect(() => {
        if (visible) {
            keepAlign();
        }
        else {
            cancelKeepAlign();
        }
        return cancelKeepAlign;
    }, [visible]);
    return <Tooltip ref={tooltipRef} {...props}/>;
}

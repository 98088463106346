var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { List } from 'rc-field-form';
import warning from '../_util/warning';
const FormList = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    warning(!!props.name, 'Form.List', 'Miss `name` prop.');
    return (<List {...props}>
      {(fields, operation) => {
        return children(fields.map(field => (Object.assign(Object.assign({}, field), { fieldKey: field.key }))), operation);
    }}
    </List>);
};
export default FormList;

import * as React from 'react';
export default function usePatchElement() {
    const [elements, setElements] = React.useState([]);
    function patchElement(element) {
        setElements(originElements => [...originElements, element]);
        return () => {
            setElements(originElements => originElements.filter(ele => ele !== element));
        };
    }
    return [elements, patchElement];
}

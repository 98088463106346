import * as React from 'react';
import useRCNotification from 'rc-notification/lib/useNotification';
import { ConfigConsumer } from '../../config-provider';
export default function createUseNotification(getNotificationInstance, getRCNoticeProps) {
    const useNotification = () => {
        // We can only get content by render
        let getPrefixCls;
        // We create a proxy to handle delay created instance
        let innerInstance = null;
        const proxy = {
            add: (noticeProps, holderCallback) => {
                innerInstance === null || innerInstance === void 0 ? void 0 : innerInstance.component.add(noticeProps, holderCallback);
            },
        };
        const [hookNotify, holder] = useRCNotification(proxy);
        function notify(args) {
            const { prefixCls: customizePrefixCls } = args;
            const mergedPrefixCls = getPrefixCls('notification', customizePrefixCls);
            getNotificationInstance(Object.assign(Object.assign({}, args), { prefixCls: mergedPrefixCls }), ({ prefixCls, instance }) => {
                innerInstance = instance;
                hookNotify(getRCNoticeProps(args, prefixCls));
            });
        }
        // Fill functions
        const hookAPI = {
            open: notify,
        };
        ['success', 'info', 'warning', 'error'].forEach(type => {
            hookAPI[type] = (args) => hookAPI.open(Object.assign(Object.assign({}, args), { type }));
        });
        return [
            hookAPI,
            <ConfigConsumer key="holder">
        {(context) => {
                ({ getPrefixCls } = context);
                return holder;
            }}
      </ConfigConsumer>,
        ];
    };
    return useNotification;
}

import * as React from 'react';
import omit from 'omit.js';
import classNames from 'classnames';
import Element from './Element';
import { ConfigConsumer } from '../config-provider';
// eslint-disable-next-line react/prefer-stateless-function
class SkeletonInput extends React.Component {
    constructor() {
        super(...arguments);
        this.renderSkeletonInput = ({ getPrefixCls }) => {
            const { prefixCls: customizePrefixCls, className, active } = this.props;
            const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
            const otherProps = omit(this.props, ['prefixCls']);
            const cls = classNames(prefixCls, className, `${prefixCls}-element`, {
                [`${prefixCls}-active`]: active,
            });
            return (<div className={cls}>
        <Element prefixCls={`${prefixCls}-input`} {...otherProps}/>
      </div>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderSkeletonInput}</ConfigConsumer>;
    }
}
SkeletonInput.defaultProps = {
    size: 'default',
};
export default SkeletonInput;

import * as React from 'react';
import { tuple } from '../_util/type';
import ListItem from './ListItem';
export const OmitProps = tuple('handleFilter', 'handleClear', 'checkedKeys');
class ListBody extends React.Component {
    constructor() {
        super(...arguments);
        this.onItemSelect = (item) => {
            const { onItemSelect, selectedKeys } = this.props;
            const checked = selectedKeys.indexOf(item.key) >= 0;
            onItemSelect(item.key, !checked);
        };
    }
    render() {
        const { prefixCls, onScroll, filteredRenderItems, selectedKeys, disabled: globalDisabled, } = this.props;
        return (<ul className={`${prefixCls}-content`} onScroll={onScroll}>
        {filteredRenderItems.map(({ renderedEl, renderedText, item }) => {
            const { disabled } = item;
            const checked = selectedKeys.indexOf(item.key) >= 0;
            return (<ListItem disabled={globalDisabled || disabled} key={item.key} item={item} renderedText={renderedText} renderedEl={renderedEl} checked={checked} prefixCls={prefixCls} onClick={this.onItemSelect}/>);
        })}
      </ul>);
    }
}
const ListBodyWrapper = (props) => <ListBody {...props}/>;
export default ListBodyWrapper;

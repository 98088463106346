import * as React from 'react';
import RcTree, { TreeNode } from 'rc-tree';
import classNames from 'classnames';
import DirectoryTree from './DirectoryTree';
import { ConfigConsumer } from '../config-provider';
import collapseMotion from '../_util/motion';
import renderSwitcherIcon from './utils/iconUtil';
export default class Tree extends React.Component {
    constructor() {
        super(...arguments);
        this.setTreeRef = (node) => {
            this.tree = node;
        };
        this.renderTree = ({ getPrefixCls, direction }) => {
            const { props } = this;
            const { prefixCls: customizePrefixCls, className, showIcon, showLine, switcherIcon, blockNode, children, } = props;
            const { checkable } = props;
            const prefixCls = getPrefixCls('tree', customizePrefixCls);
            return (<RcTree itemHeight={20} ref={this.setTreeRef} {...props} prefixCls={prefixCls} className={classNames(className, {
                [`${prefixCls}-icon-hide`]: !showIcon,
                [`${prefixCls}-block-node`]: blockNode,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            })} checkable={checkable ? <span className={`${prefixCls}-checkbox-inner`}/> : checkable} switcherIcon={(nodeProps) => renderSwitcherIcon(prefixCls, switcherIcon, showLine, nodeProps)}>
        {children}
      </RcTree>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderTree}</ConfigConsumer>;
    }
}
Tree.TreeNode = TreeNode;
Tree.DirectoryTree = DirectoryTree;
Tree.defaultProps = {
    checkable: false,
    showIcon: false,
    motion: Object.assign(Object.assign({}, collapseMotion), { motionAppear: false }),
    blockNode: false,
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import TimelineItem from './TimelineItem';
import { ConfigConsumer } from '../config-provider';
export default class Timeline extends React.Component {
    constructor() {
        super(...arguments);
        this.renderTimeline = ({ getPrefixCls, direction }) => {
            const _a = this.props, { prefixCls: customizePrefixCls, pending = null, pendingDot, children, className, reverse, mode } = _a, restProps = __rest(_a, ["prefixCls", "pending", "pendingDot", "children", "className", "reverse", "mode"]);
            const prefixCls = getPrefixCls('timeline', customizePrefixCls);
            const pendingNode = typeof pending === 'boolean' ? null : pending;
            const pendingItem = pending ? (<TimelineItem pending={!!pending} dot={pendingDot || <LoadingOutlined />}>
        {pendingNode}
      </TimelineItem>) : null;
            const timeLineItems = reverse
                ? [pendingItem, ...React.Children.toArray(children).reverse()]
                : [...React.Children.toArray(children), pendingItem];
            const getPositionCls = (ele, idx) => {
                if (mode === 'alternate') {
                    if (ele.props.position === 'right')
                        return `${prefixCls}-item-right`;
                    if (ele.props.position === 'left')
                        return `${prefixCls}-item-left`;
                    return idx % 2 === 0 ? `${prefixCls}-item-left` : `${prefixCls}-item-right`;
                }
                if (mode === 'left')
                    return `${prefixCls}-item-left`;
                if (mode === 'right')
                    return `${prefixCls}-item-right`;
                if (ele.props.position === 'right')
                    return `${prefixCls}-item-right`;
                return '';
            };
            // Remove falsy items
            const truthyItems = timeLineItems.filter(item => !!item);
            const itemsCount = React.Children.count(truthyItems);
            const lastCls = `${prefixCls}-item-last`;
            const items = React.Children.map(truthyItems, (ele, idx) => {
                const pendingClass = idx === itemsCount - 2 ? lastCls : '';
                const readyClass = idx === itemsCount - 1 ? lastCls : '';
                return React.cloneElement(ele, {
                    className: classNames([
                        ele.props.className,
                        !reverse && !!pending ? pendingClass : readyClass,
                        getPositionCls(ele, idx),
                    ]),
                });
            });
            const hasLabelItem = timeLineItems.some((item) => { var _a; return !!((_a = item === null || item === void 0 ? void 0 : item.props) === null || _a === void 0 ? void 0 : _a.label); });
            const classString = classNames(prefixCls, {
                [`${prefixCls}-pending`]: !!pending,
                [`${prefixCls}-reverse`]: !!reverse,
                [`${prefixCls}-${mode}`]: !!mode && !hasLabelItem,
                [`${prefixCls}-label`]: hasLabelItem,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className);
            return (<ul {...restProps} className={classString}>
        {items}
      </ul>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderTimeline}</ConfigConsumer>;
    }
}
Timeline.Item = TimelineItem;
Timeline.defaultProps = {
    reverse: false,
    mode: '',
};
